




<script>



/**
 * Agent Basic Info component
 */


//import DefaultAvatar from '@/assets/images/users/avatar-1.jpg'

export default {



    props: ['data','show_term_btn'],
    emit:['terminate', 'edit'],
    components: {

    },


    data() {

        return {
            agent: {
                statistic : {
                    deal_count    : 0,
                    fee_total     : 0,
                    lisitng_count : 0,
                    award         : 0,
                    balance       : 0,
                    commission_ytd : 0,
                }
            }
        }

    },
    watch: {
        data(n) {
            this.agent = n
        }
    },


    methods: {

    

    },

    created() {

    },

    mounted() {

    }

};
</script>


<template>
    <div class="row">
        <div class="card">
            <div class="card-body">

                <div class="row">
                    <div class="col-lg-2">
                        <img :src="agent.picture" alt="" class="img-thumbnail" />
                    </div>

                    <div class="col-lg-2">

                        <div class="mt-2">
                            <h5 class="font-size-16 mb-1">{{ agent.status == '0' ?'Active':'Term' }}</h5>
                            <p class="text-muted mb-0">VIN:{{ agent.code }}</p>
                        </div>
                     
                        <div class="mt-2">
                            <p class="text-muted mb-1">Office :</p>
                            <h5 class="font-size-14 text-truncate">{{ agent.office_name }}</h5>
                        </div>
                        <div class="mt-2">
                            <p class="text-muted mb-1">Has Contract :</p>
                            <h5 class="font-size-14 text-truncate">Yes (30 days left)</h5>
                        </div>
                    </div>

                    <div class="col-lg-2">
                        <div class="mt-2">
                            <p class="text-muted mb-1">E-mail :</p>
                            <h5 class="font-size-14 text-truncate">{{ agent.email }}</h5>
                        </div>

                        <div class="mt-2">
                            <p class="text-muted mb-1">Phone :</p>
                            <h5 class="font-size-14 text-truncate">
                                {{ agent.phone }}
                            </h5>
                        </div>
                        <div class="mt-2">
                            <p class="text-muted mb-1">Is PREC </p>
                            <h5 class="font-size-14 text-truncate">{{ agent.isPREC?'Yes':'No' }}</h5>
                        </div>
                    </div>


                    <div class="col-lg-2">
                        <div class="mt-2">
                            <p class="text-muted mb-1">DEAL Count YTD :</p>
                            <h5 class="font-size-14 text-truncate">{{agent.statistic.deal_count}}</h5>
                        </div>

                        <div class="mt-2">
                            <p class="text-muted mb-1">Commission YTD :</p>
                            <h5 class="font-size-14 text-truncate">
                                ${{ agent.statistic.commission_ytd.toLocaleString() }}
                            </h5>
                        </div>
                        <div class="mt-2">
                            <p class="text-muted mb-1">Contribution </p>
                            <h5 class="font-size-14 text-truncate">${{ agent.statistic.fee_total.toLocaleString() }}</h5>
                        </div>
                    </div>


                    <div class="col-lg-2">
                        <div class="mt-2">
                            <p class="text-muted mb-1">Listing Count :</p>
                            <h5 class="font-size-14 text-truncate">{{agent.statistic.lisitng_count}}</h5>
                        </div>

                        <div class="mt-2">
                            <p class="text-muted mb-1">Award :</p>
                            <h5 class="font-size-14 text-truncate">
                                ${{agent.statistic.award.toLocaleString()}}
                            </h5>
                        </div>
                        <div class="mt-2">
                            <p class="text-muted mb-1">Balance Owing</p>
                            <h5 class="font-size-14 text-truncate"> ${{agent.statistic.balance.toLocaleString()}}</h5>
                        </div>
                    </div>
                    <div class="col-lg-1">
                        
                    </div>

                    <div class="col-lg-1">
                        <div  class="mt-4 d-flex align-items-right flex-wrap justify-content-end">
                            <b-button variant="primary" @click="$emit('edit')">Edit</b-button>
                        </div>

                        <div class="mt-4 d-flex align-items-right flex-wrap justify-content-end">
                            <b-button variant="danger" @click="$emit('terminate')">Terminate</b-button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!-- end card -->
    </div>
</template>